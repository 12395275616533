import { AiOutlineSnippets, AiOutlinePartition } from 'react-icons/ai';
import '../../styles/components/KPICard.css';
import LoadingDiv from '../UI/LoadingDiv';

export default function KPICard({ name1, percentage1, name2, percentage2 }) {
  if (!percentage1 || !percentage2) {
    return <LoadingDiv />;
  }

  return (
    <div className="KPICard-container">
      <div className="KPICard-item">
        <div className="KPICard-number-icon">
          <AiOutlineSnippets />
        </div>
        <div className="KPICard-number-value">{percentage2}</div>
        <div className="KPICard-number-description">{name2}</div>
      </div>
      <div className="KPICard-item">
        <div className="KPICard-number-icon">
          <AiOutlinePartition />
        </div>
        <div className="KPICard-number-value">{percentage1}</div>
        <div className="KPICard-number-description">{name1}</div>
      </div>
    </div>
  );
}
