import fetchVacancies from '../api/vacancies';
import fetchCompanies from '../api/companies';

export function getDate(date) {
  return date?.split('T')[0];
}

export function getSubstring(string, char1, char2) {
  return string.slice(string.indexOf(char1) + 1, string.lastIndexOf(char2));
}

export function ratePosition(position) {
  const positionRatings = {
    executive: 5,
    director: 4,
    manager: 4,
    'mid-senior': 3,
    senior: 3,
    entry: 1,
  };

  if (position) {
    const lowercasePosition = position.toLowerCase();

    for (const level in positionRatings) {
      const levelWords = level.split(' ');

      const isMatchingLevel = levelWords.some((word) =>
        lowercasePosition.includes(word)
      );

      if (isMatchingLevel) {
        return positionRatings[level];
      }
    }

    return 1;
  } else return 0;
}

export function getMoreReadability(text) {
  const textWithSpaces = text
    .replace(/\be\.g\./g, 'e"g"')
    .replace(/\betc\./g, 'etc"')
    .replace(/:/g, ': ')
    .replace(/;/g, '; ')
    .replace(/,/g, ', ');
  const paragraphs = textWithSpaces.match(/[^.\r\n!?\n]+(?:[.\r\n!?\n]+|$)/g);

  if (!paragraphs) {
    return [];
  }

  const fixedParagraphs = paragraphs.map((paragraph) =>
    paragraph.replace(/e"g"/g, 'e.g.').replace(/etc"/g, 'etc.')
  );

  return fixedParagraphs;
}

export function groupVacanciesByFunction(vacancies) {
  const grouped = {};

  vacancies.forEach((vacancy) => {
    if (vacancy.function) {
      const functions = vacancy.function
        .split(/(?:\s*,\s*|\s*and\s*)/)
        .map((f) => f.trim());

      functions.forEach((func) => {
        if (!func) return; // Skip empty functions

        if (!grouped[func]) {
          grouped[func] = [];
        }

        grouped[func].push(vacancy);
      });
    }
  });

  // console.log(grouped);
  return grouped;
}

export async function fetchAndUpdateCache(
  cacheKeys,
  setVacancies,
  setCompanies
) {
  const [CACHE_KEY1, CACHE_KEY2] = cacheKeys;

  try {
    if (cacheKeys.includes(CACHE_KEY1)) {
      const vacData = await fetchVacancies();
      setVacancies(vacData);

      // Store the fetched data along with the current timestamp in localStorage
      const cacheData1 = {
        timestamp: Date.now(),
        vacData,
      };
      localStorage.setItem(CACHE_KEY1, JSON.stringify(cacheData1));
    }

    if (cacheKeys.includes(CACHE_KEY2)) {
      const compData = await fetchCompanies();
      setCompanies(compData);

      // Store the fetched data along with the current timestamp in localStorage
      const cacheData2 = {
        timestamp: Date.now(),
        compData,
      };
      localStorage.setItem(CACHE_KEY2, JSON.stringify(cacheData2));
    }

    // if (cacheKeys.includes(CACHE_KEY3)) {
    //   // Store the fetched data along with the current timestamp in localStorage
    //   const cacheData3 = {
    //     timestamp: Date.now(),
    //     favVacancies,
    //   };
    //   localStorage.setItem(CACHE_KEY3, JSON.stringify(cacheData3));
    // }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
