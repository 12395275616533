import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from 'react-icons/md';
import Rating from '../UI/Rating';
import '../../styles/components/CompanyCardComponent.css';

export default function CategoryCard({
  category,
  vacancies,
  isSelected,
  onClick,
}) {
  const cardRef = useRef(null);
  const [isCardOpened, setIsCardOpened] = useState(false);
  const isCardDisabled = vacancies.length === 0;

  useEffect(() => {
    setIsCardOpened(isSelected);
  }, [isSelected]);

  useEffect(() => {
    if (isCardOpened) {
      cardRef.current.focus();
    }
  }, [isCardOpened]);

  function handleCardClick() {
    if (!isCardDisabled) {
      setIsCardOpened((prevState) => !prevState);
      onClick();
    }
  }

  return (
    <>
      <div
        className={`CompanyCard__card-link ${
          isCardOpened
            ? 'CompanyCard__selected'
            : 'CompanyCard__card-details-space'
        } ${isCardDisabled ? '' : 'CompanyCard__card-link_hover'}`}
        ref={cardRef}
        tabIndex={isCardOpened ? 0 : -1}
        onClick={handleCardClick}
      >
        <div
          className={`CompanyCard__card-details-container ${
            isCardOpened ? 'CompanyCard__focused' : ''
          }`}
        >
          <div
            className={`CompanyCard__card card-details-company ${
              isCardDisabled ? 'CompanyCard__disabled' : ''
            }`}
          >
            {category}
          </div>

          <div className="CompanyCard__card CompanyCard__arrow">
            {isCardOpened ? (
              <MdKeyboardDoubleArrowUp />
            ) : (
              <MdKeyboardDoubleArrowDown
                className={isCardDisabled ? 'CompanyCard__disabled' : ''}
              />
            )}
          </div>

          <div className="CompanyCard__card-details-vacsno ">
            ({vacancies.length})
          </div>
        </div>
      </div>
      {isCardOpened && (
        <div className="CompanyCard__card-vacancies-container">
          {vacancies &&
            vacancies
              .sort((a, b) => {
                if (b.rating !== a.rating) {
                  return b.rating - a.rating; // Sort by rating in descending order
                } else {
                  return a.title.localeCompare(b.title); // Sort by vacancy title
                }
              })
              .map((vacancy) => (
                <div
                  key={vacancy._id}
                  className="CompanyCard__card-vacancies-details-card"
                >
                  <Link
                    to={`/vacancy/${vacancy._id}`}
                    className="CompanyCard__card-vacancies-details-link"
                  >
                    <div className="CompanyCard__card-vacancies-details-title">
                      {vacancy.title},{' '}
                      {vacancy.Company.name.includes('(')
                        ? vacancy.Company.name.match(/\(([^)]+)\)/)[1]
                        : vacancy.Company.name}
                    </div>
                  </Link>
                  <div className="CompanyCard__Vacancy__rating">
                    <Rating
                      rating={vacancy.rating}
                      size={10}
                      fillColor="orange"
                      emptyColor="gray"
                    />
                  </div>
                </div>
              ))}
        </div>
      )}
    </>
  );
}
