import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Rating from '../UI/Rating';

import { getSubstring } from '../../utils/utils';
import '../../styles/components/VacancyCard.css';

export default function VacancyCard({ vacancy }) {
  const [ratingSize, setRatingSize] = useState(8);

  const updateRatingSize = () => {
    const screenSize = window.innerWidth;
    if (screenSize <= 576) {
      setRatingSize(9);
    } else if (screenSize <= 1024) {
      setRatingSize(11);
    } else {
      setRatingSize(13);
    }
  };

  useEffect(() => {
    updateRatingSize();
    window.addEventListener('resize', updateRatingSize);

    return () => {
      window.removeEventListener('resize', updateRatingSize);
    };
  }, []);

  return (
    <div className="VacancyCard__card-details-container">
      <div className="VacancyCard__card-details-company">
        <Link
          to={`/companies/${vacancy.Company._id}`}
          className="VacancyCard__card-link"
        >
          <div className="VacancyCard__card">
            {vacancy.Company.name.includes('(')
              ? getSubstring(vacancy.Company.name, '(', ')')
              : vacancy.Company.name}
          </div>
        </Link>
      </div>

      <Link to={`/vacancy/${vacancy._id}`} className="VacancyCard__card-link">
        <div className="VacancyCard__card VacancyCard__card-details-title">
          {vacancy.title}
        </div>
      </Link>

      <div className="VacancyCard__card-details-fav">
        <Rating
          rating={vacancy.rating}
          size={ratingSize}
          fillColor="orange"
          emptyColor="gray"
        />
      </div>

      <div className="VacancyCard__card VacancyCard__card-details-posted">
        {vacancy.posted}
      </div>
    </div>
  );
}
