import { ratePosition } from '../utils/utils';

// const API_URL = 'http://s3.alibond.dynu.com:3001/api/v1';
const API_URL = process.env.REACT_APP_API_URL;

export default async function fetchVacancies() {
  // console.log(`Fetching   ${API_URL}/vacancies?enabled=true`);

  const res = await fetch(`${API_URL}/vacancies?enabled=true`, {
    method: 'GET',
  });

  const data = await res.json();
  const vacancies = data?.data?.vacancies
    .map((vacancy) => ({
      ...vacancy,
      rating: ratePosition(vacancy.seniority),
    }))
    .filter((vacancy) => vacancy.rating > 0); // Filter out vacancies with rating above 0

  return vacancies;
}
