import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NavMenu from './components/NavMenu/NavMenu';
import HomePage from './pages/HomePage';
import VacanciesPage from './pages/VacanciesPage';
import FavoritesPage from './pages/FavoritesPage';
import CompaniesPage from './pages/CompaniesPage';
import AboutPage from './pages/AboutPage';
import VacancyDetailsPage from './pages/VacancyDetailsPage';
import { fetchAndUpdateCache, groupVacanciesByFunction } from './utils/utils';

const CACHE_KEY1 = 'cachedVacancies';
const CACHE_KEY2 = 'cachedCompanies';
const CACHE_KEY3 = 'cachedFavorites';
const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;

export default function App() {
  const [groupedVacancies, setGroupedVacancies] = useState({});
  const [vacancies, setVacancies] = useState([]);
  const [favVacancies, setFavVacancies] = useState([]);
  const [companies, setCompanies] = useState([]);

  // Function to check if the data in localStorage is still valid (12 hours cache time)
  const isDataValid = (timestamp) => {
    return Date.now() - timestamp < twelveHoursInMilliseconds;
  };

  useEffect(() => {
    const cachedData1 = localStorage.getItem(CACHE_KEY1);
    const cachedData2 = localStorage.getItem(CACHE_KEY2);
    const cachedData3 = localStorage.getItem(CACHE_KEY3);

    if (cachedData1 && cachedData2) {
      const { timestamp: timestamp1, vacData: data1 } = JSON.parse(cachedData1);
      const { timestamp: timestamp2, compData: data2 } =
        JSON.parse(cachedData2);

      if (isDataValid(timestamp1) && isDataValid(timestamp2)) {
        setVacancies(data1);
        setCompanies(data2);
      } else {
        fetchAndUpdateCache(
          [CACHE_KEY1, CACHE_KEY2],
          setVacancies,
          setCompanies
        );
      }
    } else {
      fetchAndUpdateCache([CACHE_KEY1, CACHE_KEY2], setVacancies, setCompanies);
    }

    if (cachedData3) {
      const { favVacancies: data3 } = JSON.parse(cachedData3);
      setFavVacancies(data3);
    }
  }, []);

  useEffect(() => {
    if (favVacancies.length) {
      const cacheData3 = {
        timestamp: Date.now(),
        favVacancies,
      };
      localStorage.setItem(CACHE_KEY3, JSON.stringify(cacheData3));
    }
  }, [favVacancies]);

  useEffect(() => {
    if (vacancies.length) {
      const grouped = groupVacanciesByFunction(vacancies);
      setGroupedVacancies(grouped);
    }
  }, [vacancies]);

  return (
    <Router>
      <header className="header no-select">
        <NavMenu />
      </header>

      <main>
        <section className="container section section__height">
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  companies={companies}
                  vacancies={vacancies}
                  groupedVacancies={groupedVacancies}
                />
              }
            />
            <Route
              path="/vacancies"
              element={<VacanciesPage vacancies={vacancies} />}
            />
            <Route
              path="/favorites"
              element={
                <FavoritesPage
                  favorites={favVacancies}
                  setFavorite={setFavVacancies}
                />
              }
            />
            <Route
              path="/companies"
              element={
                <CompaniesPage companies={companies} vacancies={vacancies} />
              }
            />
            <Route path="/about" element={<AboutPage />} />
            <Route
              path="/vacancy/:id"
              element={
                <VacancyDetailsPage
                  vacancies={vacancies}
                  favorites={favVacancies}
                  setFavorite={setFavVacancies}
                />
              }
            />
            <Route
              path="/companies/:id"
              element={
                <CompaniesPage companies={companies} vacancies={vacancies} />
              }
            />
          </Routes>
        </section>
      </main>
    </Router>
  );
}
