import { useState } from 'react';
import CategoryCard from '../components/Home/CategoryCard';
import KPICard from '../components/Home/KPICard';
import '../styles/pages/HomePage.css';

export default function HomePage({ companies, vacancies, groupedVacancies }) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCardClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="no-select">
      <div className="Homepage__background"></div>

      <div className="Homepage__container">
        <KPICard
          name1={'Companies'}
          percentage1={companies.length}
          name2={'Vacancies'}
          percentage2={vacancies.length}
        />
        <div
          className={`KPICard-info-container ${
            vacancies.length === 0 ? 'KPICard-info-loading' : ''
          }`}
        >
          <div className="KPICard-info-title">OPENINGS:</div>

          {Object.entries(groupedVacancies)
            .sort((a, b) => {
              // Sort by the length of vacancies array in descending order
              const vacanciesLengthComparison = b[1].length - a[1].length;

              if (vacanciesLengthComparison !== 0) {
                return vacanciesLengthComparison;
              }

              // If length is the same, sort alphabetically by category name
              return a[0].localeCompare(b[0]);
            })
            .sort((a, b) => {
              // Move 'Information Technology' to the beginning
              if (a[0] === 'Information Technology') return -1;
              if (b[0] === 'Information Technology') return 1;
              return 0;
            })
            .map(([cat, catVacancies]) => {
              return (
                <CategoryCard
                  key={cat}
                  category={cat}
                  vacancies={catVacancies}
                  isSelected={cat === selectedCategory}
                  onClick={() => handleCardClick(cat)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
