import '../styles/pages/AboutPage.css';

export default function AboutPage() {
  return (
    <div className="AboutPage__container no-select">
      <h1 className="AboutPage__title">About Jobs Tracker / Beta release</h1>
      <p>
        <strong className="AboutPage__bold">Welcome to Jobs Tracker</strong> –
        your ultimate companion in the world of job hunting and career
        advancement. Our app is meticulously designed to streamline your job
        search process by aggregating a diverse range of job vacancies from
        LinkedIn and other reputable sources.
      </p>

      <p>
        At Jobs Tracker, we understand that searching for the perfect job can be
        a time-consuming and overwhelming task. With the ever-growing number of
        job platforms and listings available online, it's easy to get lost in
        the sea of opportunities. That's where we come in. Our goal is to
        empower you with a user-friendly and efficient tool that not only
        compiles job openings from various sources but also presents them in a
        format that's easy to navigate, comprehend, and act upon.
      </p>

      <p>
        Your Career Journey Starts Here: Whether you're a recent graduate taking
        your first steps into the professional world, an experienced
        professional seeking new challenges, or someone looking to make a career
        pivot, Jobs Tracker is here to support you. We believe that finding the
        right job should be exciting and empowering, and that's the driving
        force behind everything we do. Thank you for choosing Jobs Tracker as
        your partner in your career advancement. We're excited to join you on
        this journey and help you unlock new opportunities that align with your
        dreams and ambitions.
      </p>

      <p className="AboutPage__signature">Happy job hunting!</p>
    </div>
  );
}
