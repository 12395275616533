import { Link } from 'react-router-dom';
import { MdOutlineOutput } from 'react-icons/md';

import { getDate, getMoreReadability } from '../../utils/utils';
import '../../styles/components/VacancyDetailsComponent.css';

export default function VacancyDetails({ vacancy }) {
  return (
    <div className="VacancyDetails__card-container VacancyDetails__modal-content">
      <div className="VacancyDetails__card-item VacancyDetails__company">
        {vacancy.country}
        <br />
        {vacancy.Company.name}
      </div>
      <div className="VacancyDetails__card-item VacancyDetails__title">
        {vacancy.title}
      </div>
      <div className="VacancyDetails__card-item">
        <Link to={vacancy.link} target="_blank">
          <button className="VacancyDetails__linkedin-link">
            <MdOutlineOutput className="VacancyDetails__linkedin-arrow" />
            {/* size={40} /> */}
          </button>
        </Link>
      </div>
      <div className="VacancyDetails__card-item VacancyDetails__details">
        <strong>{vacancy.seniority}</strong>,<br></br>
        {vacancy.function},<br></br>
        {vacancy.industries},<br></br>
        {vacancy.employment}
      </div>
      <div className="VacancyDetails__card-item VacancyDetails__posted">
        {getDate(vacancy.lastUpdated)}
      </div>
      <div className="VacancyDetails__card-item VacancyDetails__description">
        {getMoreReadability(vacancy.description).map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
    // </div>
  );
}
