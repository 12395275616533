import { useEffect, useState } from 'react';
import VacancyCard from '../components/Vacanccies/VacancyCard';
// import { ratePosition } from '../utils/utils';

import '../styles/pages/VacanciesPage.css';
import LoadingDiv from '../components/UI/LoadingDiv';

export default function VacanciesPage({
  vacancies,
  favVacancies,
  setFavorite,
}) {
  const [sortedVacancies, setSortedVacancies] = useState([]);

  useEffect(() => {
    setSortedVacancies(vacancies.sort((a, b) => b.rating - a.rating));
  }, [vacancies]);

  return (
    <div className="no-select">
      <h1 className="vacancies__page-title">All Vacancies</h1>
      <div className="vacancies__grid-container">
        {vacancies.length === 0 ? (
          <LoadingDiv />
        ) : (
          sortedVacancies.map((vacancy) => {
            const isVacancyFavorite =
              favVacancies?.some(
                (favVacancy) => favVacancy._id === vacancy._id
              ) || false;

            return (
              <VacancyCard
                key={vacancy._id}
                vacancy={vacancy}
                isFavorite={isVacancyFavorite}
                setFavorite={setFavorite}
              />
            );
          })
        )}
      </div>
      {/* <div className="vacancies__grid-container">
        {sortedVacancies.map((vacancy) => {
          const isVacancyFavorite =
            favVacancies?.some(
              (favVacancy) => favVacancy._id === vacancy._id
            ) || false; // Default to false if favVacancies is undefined

          return (
            <VacancyCard
              key={vacancy._id}
              vacancy={vacancy}
              isFavorite={isVacancyFavorite}
              setFavorite={setFavorite}
            />
          );
        })}
      </div> */}
    </div>
  );
}
