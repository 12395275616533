// const API_URL = 'http://s3.alibond.dynu.com:3001/api/v1';
const API_URL = process.env.REACT_APP_API_URL;

export default async function fetchCompanies() {
  const res1 = await fetch(`${API_URL}/companies?enabled=true`, {
    method: 'GET',
  });

  const data1 = await res1.json();

  const res2 = await fetch(`${API_URL}/vacancies?enabled=true`, {
    method: 'GET',
  });

  const data2 = await res2.json();

  const companies = data1?.data?.companies.map((comp) => ({
    ...comp,
    openings: data2?.data?.vacancies.filter(
      (vac) => vac.Company._id === comp._id
    ).length,
  }));

  return companies;
}
