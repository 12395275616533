import { useEffect, useState } from 'react';
import '../styles/pages/FavoritesPage.css';
import VacancyCard from '../components/Vacanccies/VacancyCard';

export default function FavoritesPage({ favorites, setFavorite }) {
  const [sortedFavorites, setSortedVacancies] = useState([]);

  useEffect(() => {
    setSortedVacancies(favorites.sort((a, b) => b.rating - a.rating));
  }, [favorites]);

  return (
    <div>
      <h1 className="favorites__page-title">Favorites</h1>
      <div className="vacancies__grid-container">
        {favorites.length === 0 ? (
          <div>No favorite added.</div>
        ) : (
          sortedFavorites.map((vacancy) => (
            <VacancyCard
              key={vacancy._id}
              vacancy={vacancy}
              isFavorite={true}
              setFavorite={setFavorite}
            />
          ))
        )}
      </div>
    </div>
  );
}
