import { MdStar } from 'react-icons/md';

export default function Rating({
  rating = 1,
  size = 2,
  fillColor = '#ffc107',
  emptyColor = '#e4e5e9',
}) {
  return (
    <div>
      {Array(5)
        .fill()
        .map((_, i) => {
          const ratingValue = i + 1;
          return (
            <span key={i}>
              <MdStar
                // className="star"
                color={ratingValue <= rating ? fillColor : emptyColor}
                size={size}
              />
            </span>
          );
        })}
    </div>
  );
}
