import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { MdArrowCircleLeft } from 'react-icons/md';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';

import VacancyDetails from '../components/Vacanccies/VacancyDetails';
import '../styles/pages/VacancyDetailsPage.css';
import LoadingDiv from '../components/UI/LoadingDiv';

export default function VacancyDetailsPage({
  vacancies,
  favorites,
  setFavorite,
}) {
  const { id } = useParams();
  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();
  const vacancy = vacancies.find((v) => v._id === id);
  const isFavorite = favorites.some((fav) => fav._id === id); // Determine if it's a favorite

  // const handleGoBack = () => {
  //   navigate(-1); // This will navigate back to the previous page in the history
  // };

  const handleFavoriteCick = () => {
    if (vacancy) {
      // const isFavorite = favorites.some((fav) => fav._id === id);

      if (isFavorite) {
        // Already in favorites, remove
        setFavorite(favorites.filter((v) => v._id !== id));
      } else {
        // Not in favorites, add
        setFavorite([...favorites, vacancy]);
      }
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div className="VacanyDetailsPage__details-container">
        <MdArrowCircleLeft
          className="VacanyDetailsPage__back-button"
          onClick={() => navigate(-1)}
        />
        <div className="VacanyDetailsPage__fav-button-container">
          {isFavorite ? (
            <AiFillStar
              className="VacanyDetailsPage__fav-button"
              onClick={handleFavoriteCick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          ) : (
            <AiOutlineStar
              className="VacanyDetailsPage__fav-button"
              onClick={handleFavoriteCick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
          {showTooltip && (
            <div className="VacanyDetailsPage__tooltip">Add to favorites</div>
          )}
        </div>
      </div>
      {/* <div>
        {!vacancy && <LoadingDiv />}
        {vacancy && <VacancyDetails vacancy={vacancy} />}
      </div> */}

      <div>
        {vacancy ? <VacancyDetails vacancy={vacancy} /> : <LoadingDiv />}
      </div>
    </>
  );
}
