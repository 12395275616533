import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanyCard from '../components/Companies/CompanyCard';
import '../styles/pages/CompaniesPage.css';
import LoadingDiv from '../components/UI/LoadingDiv';

export default function CompaniesPage({ companies, vacancies }) {
  const { id } = useParams();
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  useEffect(() => {
    if (id) {
      setSelectedCompanyId(id);
    }
  }, [id, vacancies]);

  const handleCardClick = (companyId) => {
    setSelectedCompanyId(companyId);
  };

  return (
    <div className="no-select">
      <h1 className="CompaniesPage__page-title">All Companies</h1>
      <div className="CompaniesPage__grid-container">
        {companies.length === 0 ? (
          <LoadingDiv />
        ) : (
          companies
            .sort((compA, compB) => {
              // Sort by openings in descending order
              if (compB.openings !== compA.openings) {
                return compB.openings - compA.openings;
              }
              // If openings are the same, sort by name in ascending order
              return compA.name.localeCompare(compB.name);
            })
            .map((comp) => (
              <CompanyCard
                key={comp._id}
                company={comp}
                vacancies={vacancies.filter(
                  (vac) => vac.Company._id === comp._id
                )}
                isSelected={comp._id === selectedCompanyId}
                onClick={() => handleCardClick(comp._id)}
              />
            ))
        )}
      </div>
    </div>
  );
}
