import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/icons/logo.png';
import {
  AiOutlineSnippets,
  AiOutlineStar,
  AiOutlineHome,
  AiOutlinePartition,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import '../../styles/components/NavMenu.css';

export default function NavMenu() {
  const [pageName, setPageName] = useState('Jobs Tracker');
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 766);
  const location = useLocation();

  // Define updateScreenWidth using useCallback to memoize the function
  const updateScreenWidth = useCallback(() => {
    setIsWideScreen(window.innerWidth > 766);
    if (isWideScreen) setPageName('Jobs Tracker');
  }, [isWideScreen]);

  useEffect(() => {
    // Add a resize event listener to update the state when the window is resized
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [updateScreenWidth]);

  const handleLinkClick = (titleMsg) => {
    if (!isWideScreen) setPageName(titleMsg);
  };

  return (
    <nav className="nav container">
      <Link
        to="/"
        className="nav__logo"
        onClick={() => handleLinkClick('Jobs Tracker')}
      >
        <div className="nav__logotitle">
          <img src={Logo} className="logo__svg" alt="Logo" />
          <span className="logo__pagename">{pageName}</span>
          <img src={Logo} className="logo__svg backup_logo" alt="Logo" />
        </div>
      </Link>

      <div className="nav__menu" id="nav-menu">
        <ul className="nav__list">
          <li className="nav__item">
            <Link
              to="/vacancies"
              className={
                location.pathname === '/vacancies' ||
                location.pathname.startsWith('/vacancy/')
                  ? 'nav__link active-link'
                  : 'nav__link'
              }
              onClick={() => handleLinkClick('Vacancies')}
            >
              {/* <i className="bx bx-conversation nav__icon"></i> */}

              <AiOutlineSnippets className="nav__icon" />
              <span className="nav__name">Vacancies</span>
            </Link>
          </li>

          <li className="nav__item">
            <Link
              to="/favorites"
              className={
                location.pathname === '/favorites'
                  ? 'nav__link active-link'
                  : 'nav__link'
              }
              onClick={() => handleLinkClick('Favorites')}
            >
              {/* <i className="bx bx-heart nav__icon"></i> */}
              <AiOutlineStar className="nav__icon" />
              <span className="nav__name">Favorites</span>
            </Link>
          </li>

          <li className="nav__item">
            {/* <Link to="#home" className="nav__link active-link"> */}
            <Link
              to="/"
              className={
                location.pathname === '/'
                  ? 'nav__link active-link'
                  : 'nav__link'
              }
              onClick={() => handleLinkClick('Jobs Tracker')}
            >
              {/* <i className="bx bx-home-circle nav__icon"></i> */}
              <AiOutlineHome className="nav__icon" />
              <span className="nav__name">Home</span>
            </Link>
          </li>

          <li className="nav__item">
            <Link
              to="/companies"
              className={
                location.pathname.startsWith('/companies')
                  ? 'nav__link active-link'
                  : 'nav__link'
              }
              onClick={() => handleLinkClick('Companies')}
            >
              {/* <i className="bx bx-buildings nav__icon"></i> */}
              <AiOutlinePartition className="nav__icon" />
              <span className="nav__name">Companies</span>
            </Link>
          </li>

          <li className="nav__item">
            <Link
              to="/about"
              className={
                location.pathname === '/about'
                  ? 'nav__link active-link'
                  : 'nav__link'
              }
              onClick={() => handleLinkClick('About Us')}
            >
              {/* <i className="bx bx-comment-detail nav__icon"></i> */}
              <AiOutlineExclamationCircle className="nav__icon" />
              <span className="nav__name">About</span>
            </Link>
          </li>
        </ul>
      </div>

      {/* <img src="assets/images/perfil.png" alt="" className="nav__img" /> */}
      {/* <img src={img1} alt="" className="nav__img" /> */}
    </nav>
  );
}
